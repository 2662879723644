import React, { useEffect, useContext, useState, useCallback, Suspense } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

// user context
import { UserContext, DispatchUserContext } from 'contexts/userContext';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import formatDollar from 'helpers/formatDollar';
import scrollToTop from 'helpers/scrollToTop';

// Term comparison insurers
import { insurer_term, userContext_term } from './comparisonData';

// dropdown values
const term = [
  { label: 'To age 99/100', term: "PayToMax" },
  { label: 'To Age 85', term: "PayTo85" },
  { label: 'To Age 75', term: "PayTo75" },
  { label: 'To Age 70', term: "PayTo70" },
  { label: 'To Age 65', term: "PayTo65" },
  // { label: '30 years', term: "30Yrs" },
  // { label: '25 years', term: "25Yrs" },
  // { label: '20 years', term: "20Yrs" },
];

const deathAmt = [
  { label: '$2,000,000', amt: 2000000 },
  { label: '$1,500,000', amt: 1500000 },
  { label: '$1,000,000', amt: 1000000 },
  // { label: '$500,000', amt: 500000 },
];

const tpdAmt = [
  { label: '$2,000,000', amt: 2000000 },
  { label: '$1,500,000', amt: 1500000 },
  { label: '$1,000,000', amt: 1000000 },
  // { label: '$500,000', amt: 500000 },
  { label: 'Not required', amt: 0 },
];

const ciAmt = [
  { label: '$1,000,000', amt: 1000000 },
  { label: '$500,000', amt: 500000 },
  { label: '$250,000', amt: 250000 },
  { label: '$200,000', amt: 200000 },
  { label: '$100,000', amt: 100000 },
  { label: 'Not required', amt: 0 },
];

const eciAmt = [
  { label: '$250,000', amt: 250000 },
  { label: '$200,000', amt: 200000 },
  { label: '$150,000', amt: 150000 },
  { label: '$100,000', amt: 100000 },
  { label: '$50,000', amt: 50000 },
  { label: 'Not required', amt: 0 },
];

// formik
const initialValues = {
  term: "PayTo75",
  death: 2000000,
  tpd: 2000000,
  ci: 0,
  eci: 0
}

// https://github.com/jquense/yup/issues/538
const validationSchema = yup.object({
  term: yup.string().required("Please select coverage term"),
  death: yup.number().min(1000000, "Death cover cannot be less than $1M"),
  tpd: yup.number().max(yup.ref('death'), "TPD cover cannot be more than death cover"),
  ci: yup.number().max(yup.ref('death'), "Advanced CI cover cannot be more than death cover"),
})

// component
const CompareTerm = () => {

  // state
  const [query, setQuery] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [readyToSearch, setReadyToSearch] = useState(true);
  const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
  const [loading, setLoading] = useState(false);

  // data-table header states
  const [expandedRows, setExpandedRows] = useState(null);
  const [mobileScreen, setMobileScreen] = useState(false)
  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [filters2, setFilters2] = useState({
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // user context
  const user = useContext(UserContext);
  const dispatch = useContext(DispatchUserContext);

  // useEffect to check if insured details is in comparison context
  const navigate = useNavigate();

  // custom axios instance
  const axiosInstance = useAxiosAuth();

  // check screen size to decide what label to display
  const screenSize = useScreenSize();

  // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
  useEffect(() => {
    setMobileScreen(screenSize.width <= 600);
  }, [screenSize.width])

  useEffect(() => {
    // return to insured details page if insured details are not entered
    if (!user.comparisonInsured) {
      return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // to activate after form is completed
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const res = await axiosInstance.post('dashboard/comparison/term', query);
        if (res.data) {

          // ======================================================
          // Preparing the data from repsonse
          // ======================================================

          // console.log("---> res.data", res.data);
          let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
          const productArr = res.data.result[1];  // array that contains all product info for this category 
          // console.log("productArr", productArr)

          // sort premiumArr by premium (descending) follow by insurer name (ascending) name
          premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc");
          // console.log("---> premiumArr", premiumArr);


          // todo: to change teh logic to enable multiple plan from a single insurer

          // getting the list of product name from res.data
          let planList = premiumArr.map(entry => entry.planName.trim());
          planList = [...new Set(planList)]; // remove duplicate to reduce number of iteration
          // console.log("---> planList", planList);


          // ======================================================
          // check if the plan has the corresponding coverage for CI & ECI
          // ======================================================
          planList = planList.map(plan => {
            const ci = (query.ci === 0)
              ? true // if ci is not required, then return true for this plan
              : premiumArr.find(entry => entry.cover === "ci" && entry.planName === plan) ? true : false; // else check if plan has ci

            const eci = (query.eci === 0)
              ? true // if eci is not required, then return true for this plan
              : premiumArr.find(entry => entry.cover === "eci" && entry.planName === plan) ? true : false; // else check if plan has eci

            return (ci && eci) ? plan : null; // return null if plan does not have ci or eci
          });

          // remove null from planList
          planList = planList.filter(plan => plan !== null);
          // console.log("---> planList @ position 2", planList);

          // ======================================================
          // filter premiumArr by planList and create the final array to be displayed
          // ======================================================



          const combineArrData = planList.map(entry => {

            console.log("#### start of map function ####")

            // filter all entry for the current plan into planData
            const planData = premiumArr.filter(p => p.planName === entry);
            console.log("---> data", planData);

            // reducer array function to add all the premium in planData
            const totalPremium = planData.reduce((acc, curr) => acc + curr.premium, 0);
            console.log("---> totalPremium", totalPremium);

            // getting the insurer & plan name to match the corresponding product info
            const insurerName = planData[0].insurer.trim();
            const planName = planData[0].planName.trim();
            console.log("---> insurerName", insurerName);
            console.log("---> planName", planName);

            // to find the corresponding product in the productArr
            const productInfo = productArr.filter(p => p.insurerName === insurerName && p.planName === planName)// end filter
            console.log("---> productInfo", productInfo);

            // ======================================================
            // create the display entry
            // ======================================================

            console.log("planData", planData )
            console.log("productInfo[0]", productInfo[0])

            const displayEntry = {
              _id: productInfo[0]?._id,
              insurer: insurerName,
              planName,
              type: planData[0]?.policyTermType, // query.term,
              policyTerm: planData[0]?.policyTerm,
              premium: totalPremium,
              death: query.death,
              updatedAt: planData[0]?.updatedAt,
              details: productInfo[0]
            };

            query.tpd > 0 && (displayEntry.tpd = query.tpd);
            query.ci > 0 && (displayEntry.ci = query.ci);
            query.eci > 0 && (displayEntry.eci = query.eci);
            // console.log("---> displayEntry", displayEntry);

            return productInfo.length > 0
              ? { ...displayEntry, details: productInfo[0], promo: productInfo[0]?.customerPromo?.valid }
              : { ...displayEntry, details: {}, promo: false, }

          }) // end map
          // console.log("---> combineArrData", combineArrData);

          

          // ======================================================
          // * IMPORTANT: This is the old logic to display the comparison table by insurer.
          // * This logic is not used anymore as we are now displaying by plan
          // * The logic is kept here for reference
          // * DO NOT DELETE THIS CODE
          // ======================================================

              /*
              // getting the list of insurer name from res.data
              let insurerList = premiumArr.map(entry => entry.insurer.trim());
              insurerList = [...new Set(insurerList)]; // remove duplicate to reduce number of iteration
              // console.log("---> insurerList @ 1", insurerList);

              // ======================================================
              // check if the insurer has the corresponding coverage for CI & ECI
              // ======================================================
              insurerList = insurerList.map(insurer => {
                const ci = (query.ci === 0)
                  ? true // if ci is not required, then return true for this insurer
                  : premiumArr.find(entry => entry.cover === "ci" && entry.insurer === insurer) ? true : false; // else check if insurer has ci

                const eci = (query.eci === 0)
                  ? true // if eci is not required, then return true for this insurer
                  : premiumArr.find(entry => entry.cover === "eci" && entry.insurer === insurer) ? true : false; // else check if insurer has eci

                return (ci && eci) ? insurer : null; // return null if insurer does not have ci or eci
              })

              // remove null from insurerList
              insurerList = insurerList.filter(insurer => insurer !== null);
              // console.log("---> insurerList @ 2", insurerList);
              // console.log("---> query", query)

              // ======================================================
              // filter premiumArr by insurerList and create the final array to be displayed
              // ======================================================
              const combineArrData = insurerList.map(entry => {

                // filter all entry for the current insurer into insurerData
                const insurerData = premiumArr.filter(p => p.insurer === entry);
                // console.log("---> data", insurerData);

                // reducer array function to add all the premium in insurerData
                const totalPremium = insurerData.reduce((acc, curr) => acc + curr.premium, 0);
                // console.log("---> totalPremium", totalPremium);

                // getting the insurer & plan name to match the corresponding product info
                const insurerName = insurerData[0].insurer.trim();
                const planName = insurerData[0].planName.trim();
                // console.log("---> insurerName", insurerName);
                // console.log("---> planName", planName);

                // to find the corresponding product in the productArr
                const productInfo = productArr.filter(p => p.insurerName === insurerName && p.planName === planName)// end filter
                // console.log("---> productInfo", productInfo);

                // ======================================================
                // create the display entry
                // ======================================================

                // console.log("insurerData", insurerData )
                // console.log("productInfos", productInfo[0])

                const displayEntry = {
                  _id: productInfo[0]._id,
                  insurer: insurerName,
                  planName,
                  type: query.term,
                  policyTerm: insurerData[0].policyTerm,
                  premium: totalPremium,
                  death: query.death,
                  updatedAt: insurerData[0].updatedAt,
                  details: productInfo[0]
                };
                query.tpd > 0 && (displayEntry.tpd = query.tpd);
                query.ci > 0 && (displayEntry.ci = query.ci);
                query.eci > 0 && (displayEntry.eci = query.eci);
                // console.log("---> displayEntry", displayEntry);

                return productInfo.length > 0
                  ? { ...displayEntry, details: productInfo[0], promo: productInfo[0]?.customerPromo?.valid }
                  : { ...displayEntry, details: {}, promo: false, }

              }) // end map
              // console.log("---> combineArrData", combineArrData);
              */

          // ======================================================    
          // ======================================================

          const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_term)?.onlyDisplayInsurers;
          // console.log("allowedInsurers", allowedInsurers);

          // filter out the insurers that are not allowed to be displayed
          // of the allowedInsurers is empty, display all insurers
          (allowedInsurers && allowedInsurers.length > 0)
            ? setDisplayData(() => combineArrData.filter(ele => allowedInsurers.includes(ele.insurer)))
            : setDisplayData(() => combineArrData); // use the mongoData if allowedInsurers.length === 0 (display all insurers)
        }

        // close loading screen
        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
      }
    }
    // console.log("At useEffect", query);
    query.anb && getData();

  }, [axiosInstance, dispatch, navigate, query, user])

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => handleSubmit(values)
  })

  const handleSubmit = (values) => {

    // clear current display table and show loading screen
    setDisplayData(() => []);

    // console.log(values);
    // console.log(user.comparisonInsured);
    setQuery({
      anb: user.comparisonInsured.ANB,
      ageNearestBirthday: user.comparisonInsured.AnB,
      gender: user?.comparisonInsured?.gender?.charAt(0),
      smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
      ...values
    });

    setReadyToSearch(false);
    setShowEditButton(true);
    scrollToTop();
  }

  const handleReset = () => {
    // formik.resetForm();
    formik.setFieldValue("eci", 0);
    formik.setFieldValue("ci", 0);
    formik.setFieldValue("tpd", 0);
    formik.setFieldValue("death", 0);
    formik.setFieldValue("term", "");
    setDisplayData([]);
    setReadyToSearch(true);
    scrollToTop();
  }

  const handleEdit = () => {
    setReadyToSearch(true);
    setShowEditButton(false);
  }

  const generateComparison = useCallback(() => {

    // functions to format data in columns
    const formatDeath = (value) => "$" + formatDollar(value.death);
    const formatTPD = (value) => "$" + formatDollar(value.tpd);
    const formatCI = (value) => "$" + formatDollar(value.ci);
    const formatECI = (value) => "$" + formatDollar(value.eci);
    const formatPremiumTerm = (value) => value.policyTerm + " Yrs";
    const formatCurrency = (value) => value.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    const formatPromo = (value) => value.promo ? "Y" : "";

    // function to expand all and collapse all expanders
    const collapseAll = () => setExpandedRows(null);

    const expandAll = () => {
      let _expandedRows = {};
      displayData.forEach(p => _expandedRows[`${p._id}`] = true);
      setExpandedRows(_expandedRows);
    }

    // filter function for key search
    const onGlobalFilterChange2 = (e) => {
      const value = e.target.value;
      let _filters2 = { ...filters2 };
      _filters2['global'].value = value;

      setFilters2(_filters2);
      setGlobalFilterValue2(value);
    }

    const renderHeader = () => <>
      <div className="flex justify-content-between">
        <span>
          <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
          <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
        </span>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder={mobileScreen ? "Search" : "Keyword Search"}
            className={mobileScreen ? "p-inputtext-sm" : ""}
            value={globalFilterValue2}
            onChange={onGlobalFilterChange2}
          />
        </span>
      </div>
    </>

    const rowExpansionTemplate = (data) => <DisplayProductDetails data={data} />

    return <div className='col-12'>
      <div className='card'>
        <DisplayComparisonTitle type="Term Insurance" />
        <Suspense fallback={<div>Loading Comparison Table...</div>}>
          <DataTable value={displayData}
            stripedRows
            responsiveLayout="scroll"

            // header
            header={renderHeader()}

            // footer
            // footer={<DataTableFooter type={"term"} query={query}/>}

            // expander props
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="_id"

            // keyword search props
            filters={filters2} filterDisplay="row"
          >
            <Column expander style={{ width: '3em' }} />
            <Column field="insurer" header="Insurer" sortable></Column>
            <Column field="death" header="Death" body={formatDeath} sortable></Column>
            {query.tpd > 0 && <Column field="tpd" header="TPD" body={formatTPD} sortable></Column>}
            {query.ci > 0 && <Column field="ci" header="CI" body={formatCI} sortable></Column>}
            {query.eci > 0 && <Column field="eci" header="ECI" body={formatECI} sortable></Column>}
            <Column field="type" header="Type" sortable></Column>
            <Column field="policyTerm" header="Policy Term" body={formatPremiumTerm} sortable></Column>
            <Column field="premium" header="Annual Premium" body={formatCurrency} sortable></Column>
            <Column field="promo" header="Promo" body={formatPromo} sortable></Column>
          </DataTable>
        </Suspense>
      </div>
    </div>

  }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, query])

  return (
    <div className='grid'>
      {
        !readyToSearch && generateComparison()
      }
      <div className='col-12 md:col-6'>
        <div className='card'>
          <h5>Select coverage term</h5>
          {displayFormikLabel("term", "", formik.touched, formik.errors)}
          <Dropdown value={formik.values.term} options={term} onChange={e => formik.setFieldValue("term", e.value)} optionLabel="label" optionValue="term" className='w-full' disabled={!readyToSearch} />

          <h5>Select death coverage</h5>
          {displayFormikLabel("death", "", formik.touched, formik.errors)}
          <Dropdown value={formik.values.death} options={deathAmt} onChange={e => formik.setFieldValue("death", e.value)} optionLabel="label" optionValue="amt" className='w-full' disabled={!readyToSearch} />

          <h5>Select TPD coverage</h5>
          {displayFormikLabel("tpd", "", formik.touched, formik.errors)}
          <Dropdown value={formik.values.tpd} options={tpdAmt} onChange={e => formik.setFieldValue("tpd", e.value)} optionLabel="label" optionValue="amt" className='w-full' disabled={!readyToSearch} />

          <h5>Select advanced stages CI coverage</h5>
          {displayFormikLabel("ci", "", formik.touched, formik.errors)}
          <Dropdown value={formik.values.ci} options={ciAmt} onChange={e => formik.setFieldValue("ci", e.value)} optionLabel="label" optionValue="amt" className='w-full' disabled={!readyToSearch} />

          <h5>Select early-stages CI coverage</h5>
          {displayFormikLabel("eci", "", formik.touched, formik.errors)}
          <Dropdown value={formik.values.eci} options={eciAmt} onChange={e => formik.setFieldValue("eci", e.value)} optionLabel="label" optionValue="amt" className='w-full' disabled={!readyToSearch} />

          <div className='mt-5'>
            <hr></hr>
            <div className='grid mt-3'>
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
              </div>
              {
                showEditButton && <div className='col-12 md:col-4'>
                  <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                </div>
              }
              <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 md:col-6'>
        <DisplayInsuredDetails />
        <FormInsurerDisplayedOption comparisonType={userContext_term} insurerList={insurer_term} />
      </div>
      {loading && <LoadingScreen />}
    </div>
  )
}

export default CompareTerm
import React, { useState, useContext, useRef } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

// PrimeReact
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Messages } from 'primereact/messages';

// custom axios hook
import useAxiosAuth from 'hooks/useAxiosAuth'

// context
import { UserContext, DispatchUserContext } from 'contexts/userContext';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// formik validation schema
const validationSchema = yup.object({
    discord: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    facebook: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    instagram: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    linkedin: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    reddit: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    telegram: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    twitter: yup.string().url("Please enter a valid URL").nullable().notRequired(),
    youtube: yup.string().url("Please enter a valid URL").nullable().notRequired(),
});

// formik initial values
const initialValues = {
    discord: "",
    facebook: "",
    instagram: "",
    linkedin: "",
    reddit: "",
    telegram: "",
    twitter: "",
    youtube: ""
}

// component
const FormSocialMediaAccountUpdate = () => {

    // state to update preferred name and mobile
    const [beginUpdate, setBeginUpdate] = useState(false)

    // useRef for Message
    const messages = useRef(null);

    // user dispatch function and details from context
    const dispatch = useContext(DispatchUserContext);
    const user = useContext(UserContext);

    // axios instance
    const axiosInstance = useAxiosAuth();

    // formik form to change info
    const formikSocial = useFormik({
        initialValues: user?.comparisonDetails?.socialMediaAccounts || initialValues,
        validationSchema,
        onSubmit: (values) => updateSocial(values)
    })

    const updateSocial = async (values) => {

        // find all keys of values and trim all values
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                values[key] = values[key].trim();
            }
        }
        // console.table(values);

        try {
            const res = await axiosInstance.post('dashboard/profile/updateSocialMediaAccAccount', values);
            const { status, data } = res;
            console.log("res.data", res?.data);

            if (status === 200 & data?.success) {
                // update user context
                dispatch({ type: 'UPDATE_SOCIAL', value: values });
                messages.current.show({ severity: 'success', summary: 'Success', detail: 'Social Media Account Updated' });
                handleClearUpdate();
            } else {
                messages.current.show({ severity: 'error', summary: 'Error', detail: res?.data?.msg });
            }
        } catch (error) {
            console.log("error", error);
            primeMesagesErrorCatcher(messages, error);
        }
        handleClearUpdate();
    }

    const handleBeginUpdate = () => setBeginUpdate(true);
    const handleClearUpdate = () => setBeginUpdate(false);

    const displayExistingAccount = (name, displayName) => (user?.comparisonDetails?.socialMediaAccounts[name] === "")
        ? <li>{displayName}</li>
        : <>
            <li>{displayName}</li>
            <ul>
                <li><a href={user?.comparisonDetails?.socialMediaAccounts[name]} target="_blank" rel="noreferrer">{user?.comparisonDetails?.socialMediaAccounts[name]}</a></li>
            </ul>
        </>

    console.log("user", user);
    // console.table(formikSocial.values);

    return (
        <div className='field card'>
            < Messages ref={messages} />
            <h6>Click [Update Social] to update the following.</h6>

            <div className='grid'>
                {beginUpdate
                    ? <>
                        <div className="col-12 mt-3">

                            {displayFormikLabel("discord", "Your Discord Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="discord" className="w-full mb-3" value={formikSocial.values.discord} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("discord", e.target.value)} />

                            {displayFormikLabel("facebook", "Your Facebook Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="mobile" className="w-full mb-3" value={formikSocial.values.facebook} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("facebook", e.target.value)} />

                            {displayFormikLabel("instagram", "Your Instagram Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="instagram" className="w-full mb-3" value={formikSocial.values.instagram} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("instagram", e.target.value)} />

                            {displayFormikLabel("linkedin", "Your LinkedIn Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="linkedin" className="w-full mb-3" value={formikSocial.values.linkedin} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("linkedin", e.target.value)} />

                            {displayFormikLabel("reddit", "Your Reddit Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="reddit" className="w-full mb-3" value={formikSocial.values.reddit} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("reddit", e.target.value)} />

                            {displayFormikLabel("telegram", "Your Telegram Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="telegram" className="w-full mb-3" value={formikSocial.values.telegram} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("telegram", e.target.value)} />

                            {displayFormikLabel("twitter", "Your Twitter Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="twitter" className="w-full mb-3" value={formikSocial.values.twitter} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("twitter", e.target.value)} />

                            {displayFormikLabel("youtube", "Your YouTube Link", formikSocial.touched, formikSocial.errors)}
                            <InputText id="youtube" className="w-full mb-3" value={formikSocial.values.youtube} disabled={!beginUpdate} onChange={(e) => formikSocial.setFieldValue("youtube", e.target.value)} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Button type="button" label="Cancel" aria-label="Cancel" className="p-button-outlined w-full" onClick={handleClearUpdate} />
                        </div>
                        <div className="col-12 md:col-6">
                            <Button type="submit" label="Update" aria-label="Update" className="w-full" onClick={formikSocial.handleSubmit} />
                        </div>
                    </>
                    : <div className='col-12'>
                        <ol>
                            {displayExistingAccount("discord", "Discord")}
                            {displayExistingAccount("facebook", "Facebook")}
                            {displayExistingAccount("instagram", "Instagram")}
                            {displayExistingAccount("linkedin", "LinkedIn")}
                            {displayExistingAccount("reddit", "Reddit")}
                            {displayExistingAccount("telegram", "Telegram")}
                            {displayExistingAccount("twitter", "X (Twitter)")}
                            {displayExistingAccount("youtube", "YouTube")}
                        </ol>
                        <br></br>
                        <Button type="button" label="Update Social" aria-label="Update Info" className="w-full" onClick={handleBeginUpdate} />
                    </div>}
            </div>
        </div >
    )
}

export default FormSocialMediaAccountUpdate
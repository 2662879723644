const insurer_integratedShield = ["AIA", "HSBC Life", "Income", "Raffles Health Insurance", "Singlife"];
const insurer_longTermCare = ["Income", "Singlife"];
const insurer_lifetimeIncome = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const insurer_multiPayCI = ["AIA", "FWD", "Manulife", "Singlife", "Tokio Marine"];
const insurer_retirementIncome = ["AIA", "CTPIS", "Income", "Manulife", "Singlife"];
const insurer_term = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife", "Tokio Marine"];
const insurer_universalLifeIndex = ["AIA", "HSBC Life", "Manulife"];
const insurer_wholeLifeEndowment = ["AIA", "Income", "Manulife"];
const insurer_wholeLife = ["AIA", "CTPIS", "FWD", "Income", "Manulife", "Singlife"];
const insurer_101 = ["AIA", "ETIQA", "FWD", "HSBC Life", "Income", "Singlife", "Tokio Marine" /* "Manulife" */];

const userContext_integratedShield = "integratedShield";
const userContext_longTermCare = "longTermCare";
const userContext_lifetimeIncome = "lifetimeIncome";
const userContext_multiPayCI = "multiPayCI";
const userContext_retirementIncome = "retirementIncome";
const userContext_term = "term";
const userContext_wholeLifeEndowment = "wholeLifeEndowment";
const userContext_universalLifeIndex = "universalLifeIndex";
const userContext_wholeLife = "wholeLife";
const userContext_101 = "101";

exports.insurer_integratedShield = insurer_integratedShield;        // integratedShield
exports.insurer_longTermCare = insurer_longTermCare;                // longTermCare
exports.insurer_lifetimeIncome = insurer_lifetimeIncome;            // lifetimeIncome
exports.insurer_multiPayCI = insurer_multiPayCI;                    // multiPayCI
exports.insurer_retirementIncome = insurer_retirementIncome;        // retirementIncome
exports.insurer_term = insurer_term;                                // term
exports.insurer_universalLifeIndex = insurer_universalLifeIndex;    // universalLifeIndex
exports.insurer_wholeLifeEndowment = insurer_wholeLifeEndowment;    // wholeLifeEndowment
exports.insurer_wholeLife = insurer_wholeLife;                      // wholeLife
exports.insurer_101 = insurer_101;                                  // 101

exports.userContext_integratedShield = userContext_integratedShield;        // integratedShield
exports.userContext_longTermCare = userContext_longTermCare;                // longTermCare
exports.userContext_lifetimeIncome = userContext_lifetimeIncome;            // lifetimeIncome
exports.userContext_multiPayCI = userContext_multiPayCI;                    // multiPayCI
exports.userContext_retirementIncome = userContext_retirementIncome;        // retirementIncome
exports.userContext_term = userContext_term;                                // term
exports.userContext_universalLifeIndex = userContext_universalLifeIndex;    // universalLifeIndex
exports.userContext_wholeLifeEndowment = userContext_wholeLifeEndowment;    // wholeLifeEndowment
exports.userContext_wholeLife = userContext_wholeLife;                      // wholeLife
exports.userContext_101 = userContext_101;                                  // 101
import date from 'date-and-time';
import { getCurrentTime } from 'helpers/getCurrentTime';

const investFirstMax = async (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, createExcel = true) => {

    // console.table({ monthlyPremium, premiumPaymentTerm, investmentPeriod });

    // ====
    // * Validation
    // ====

    const premiumTerm_Min = 10;
    const premiumTerm_Max = 30;

    // check if premiumPaymentTerm is within the available range
    if (premiumPaymentTerm < premiumTerm_Min || premiumPaymentTerm > premiumTerm_Max) {
        return { availablity: false }
    }

    // check if premium is within the available range

    const getMinimumInvestment = (premiumPaymentTerm) => {
        // For premium payment term 10 – 19 years, the minimum monthly investment amount is $500.
        // For premium payment term 20 – 30 years, the minimum monthly investment amount is $300.
        return premiumPaymentTerm <= 19 ? 500 : 300;
    }

    if (monthlyPremium < getMinimumInvestment(premiumPaymentTerm)) {
        return { availablity: false }
    }

    // ====
    // * Variables
    // ====

    // Policy start date
    const today = new Date();
    const currentMonth1st = new Date(today.getFullYear(), today.getMonth(), 1);
    currentMonth1st.setMinutes(currentMonth1st.getMinutes() - currentMonth1st.getTimezoneOffset()); // convert to UTC time

    // Excel file 
    const excelFileName = `FWD InvestFirstMax ${getCurrentTime()}.xlsx`

    const excelHeader = [
        // Investment Unit Account (IUA)
        { fontWeight: 'bold', value: 'Month (Index)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'IUA Monthly Premium', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', width: 28, align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'IUA units (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ IUA Units purchased', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ Booster units', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ Promo Booster units', width: 28, align: "center" },
        { fontWeight: 'bold', value: '- IUA Charge', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '$ Value (IUA)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // Accumulation Unit Account (AUA)
        { fontWeight: 'bold', value: 'Month (Index)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'AUA Monthly Premium', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Cumulative Premium', width: 28, align: "center" },

        { fontWeight: 'bold', value: 'Date (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT price (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'AUA units (Beg)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ AUA Units purchased', width: 28, align: "center" },
        { fontWeight: 'bold', value: '- AUA Charge', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ Loyalty Bonus', width: 28, align: "center" },
        { fontWeight: 'bold', value: '+ Accumulation Bonus', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'Date (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'UT price (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: 'IUA units (End)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '$ Value (AUA)', width: 28, align: "center" },
        { fontWeight: 'bold', value: '', align: "center" }, // space

        // Total Value
        { fontWeight: 'bold', value: '$ Value (Total)', width: 28, align: "center" },
    ]
    const excelData = [excelHeader];
    const createExcelSchema = () => excelHeader.map((item, index) => ({ width: [14, 29].includes(index) ? 8 : 28 }));

    // const annualPremium = Math.round(monthlyPremium * 12);
    // const totalMonths = Math.round(investmentPeriod * 12);
    // const totalPremiumMonths = Math.round(premiumPaymentTerm * 12);

    let totalInvestedPremium = 0;

    // ====
    // * Bonus Rates
    // ====

    const getBoosterBonusRate = (monthlyPremium, premiumPaymentTerm) => {

        // This caluclation is for SGD plan onlys
        let rewardBand = 0;

        if (monthlyPremium < 1000) rewardBand = 1;
        else if (monthlyPremium >= 1000 && monthlyPremium < 2000) rewardBand = 2;
        else if (monthlyPremium >= 2000 && monthlyPremium < 3000) rewardBand = 3;
        else if (monthlyPremium >= 3000 && monthlyPremium < 4000) rewardBand = 4;
        else if (monthlyPremium >= 4000) rewardBand = 5;
        else rewardBand = 0;

        // console.log("rewardBand: ", rewardBand);

        let boosterBonusRate = 0;

        if (premiumPaymentTerm >= 10 && premiumPaymentTerm <= 14) boosterBonusRate = [0, 0.23, 0.29, 0.32, 0.39, 0.44][rewardBand];// change 0.39
        else if (premiumPaymentTerm >= 15 && premiumPaymentTerm <= 19) boosterBonusRate = [0, 0.28, 0.32, 0.40, 0.47, 0.51][rewardBand];
        else if (premiumPaymentTerm >= 20 && premiumPaymentTerm <= 24) boosterBonusRate = [0, 0.34, 0.49, 0.56, 0.59, 0.63][rewardBand];
        else if (premiumPaymentTerm === 25) boosterBonusRate = [0, 0.40, 0.55, 0.80, 0.83, 0.87][rewardBand];
        else if (premiumPaymentTerm === 26) boosterBonusRate = [0, 0.41, 0.56, 0.82, 0.84, 0.89][rewardBand];
        else if (premiumPaymentTerm === 27) boosterBonusRate = [0, 0.42, 0.57, 0.83, 0.86, 0.90][rewardBand];
        else if (premiumPaymentTerm === 28) boosterBonusRate = [0, 0.44, 0.59, 0.85, 0.87, 0.92][rewardBand];
        else if (premiumPaymentTerm === 29) boosterBonusRate = [0, 0.45, 0.60, 0.86, 0.89, 0.93][rewardBand];
        else if (premiumPaymentTerm === 30) boosterBonusRate = [0, 0.49, 0.61, 0.87, 0.89, 0.93][rewardBand];
        else boosterBonusRate = 0;

        // console.log("boosterBonusRate", boosterBonusRate);
        return boosterBonusRate;
    }

    const getAdditionalBoosterBonusRate = (monthlyPremium, premiumPaymentTerm) => {

        // This caluclation is for SGD plan onlys
        let rewardBand = 0;

        if (monthlyPremium < 1000) rewardBand = 1;
        else if (monthlyPremium >= 1000 && monthlyPremium < 2000) rewardBand = 2;
        else if (monthlyPremium >= 2000 && monthlyPremium < 3000) rewardBand = 3;
        else if (monthlyPremium >= 3000 && monthlyPremium < 4000) rewardBand = 4;
        else if (monthlyPremium >= 4000) rewardBand = 5;
        else rewardBand = 0;

        // console.log("Additioal booster rewardBand: ", rewardBand);

        let additionalBoosterBonusRate = 0;

        if (premiumPaymentTerm >= 10 && premiumPaymentTerm <= 14) additionalBoosterBonusRate = [0, 0, 0, 0, 0, 0][rewardBand];
        else if (premiumPaymentTerm >= 15 && premiumPaymentTerm <= 19) additionalBoosterBonusRate = [0, 0.05, 0.12, 0.12, 0.12, 0.12][rewardBand];
        else if (premiumPaymentTerm >= 20 && premiumPaymentTerm <= 24) additionalBoosterBonusRate = [0, 0.16, 0.21, 0.24, 0.24, 0.24][rewardBand];
        else if (premiumPaymentTerm === 25) additionalBoosterBonusRate = [0, 0.33, 0.25, 0.28, 0.28, 0.28][rewardBand];
        else if (premiumPaymentTerm >= 26 && premiumPaymentTerm <= 29) additionalBoosterBonusRate = [0, 0.33, 0.26, 0.28, 0.28, 0.28][rewardBand];
        else if (premiumPaymentTerm === 30) additionalBoosterBonusRate = [0, 0.51, 0.44, 0.28, 0.28, 0.28][rewardBand];
        else additionalBoosterBonusRate = 0;

        // console.log("additionalBoosterBonusRate", additionalBoosterBonusRate);
        return additionalBoosterBonusRate;
    }

    const getLoyaltyBonusRate = (endOfYear) => {

        // * A Loyalty Bonus will be paid at the END of each policy year, throughout the policy term, starting from the 3rd policy year.

        // * During premium payment term
        // Loyalty Bonus = adjustment factor x Loyalty Bonus rate x accumulation units account value at unit price when Loyalty
        // adjustment factor will be 1 assuming no partial withdrawal

        // * After premium payment term
        // Loyalty Bonus = Loyalty Bonus rate x accumulation units account value at unit price when Loyalty

        return endOfYear >= 3 & endOfYear <= 10 ? 0.007 : 0.011; // 0.7% for 3rd to 10th year, 1.1% for 11th year onwards
    }

    const getAccumulationBonusRate = (premiumPaymentTerm, currentPolicyYear) => {

        // FWD will pay an Accumulation Bonus at certain policy years
        // The Accumulation Bonus, if payable, will be paid at the end of the policy year specified by FWD
        // This bonus will be credited to the base layer and any increase regular premium layer(s) (where applicable) on condition that: no withdrawal/ no premium reduction/ no premium holiday has been made in the last 60 months

        // * Accumulation Bonus = Accumulation Bonus rate * ACCUMULATAION UNIT ACCOUNT value at unit price when Accumulation Bonus is due

        const allAccumulationBonusrate = [
            { premiumPaymentTerm: 10, bonusRate: [{ policyYear: 10, rate: 0.02 }] },
            { premiumPaymentTerm: 11, bonusRate: [{ policyYear: 11, rate: 0.02 }] },
            { premiumPaymentTerm: 12, bonusRate: [{ policyYear: 12, rate: 0.02 }] },
            { premiumPaymentTerm: 13, bonusRate: [{ policyYear: 13, rate: 0.02 }] },
            { premiumPaymentTerm: 14, bonusRate: [{ policyYear: 14, rate: 0.02 }] },
            { premiumPaymentTerm: 15, bonusRate: [{ policyYear: 10, rate: 0.02 }, { policyYear: 15, rate: 0.02 }] },
            { premiumPaymentTerm: 16, bonusRate: [{ policyYear: 11, rate: 0.02 }, { policyYear: 16, rate: 0.02 }] },
            { premiumPaymentTerm: 17, bonusRate: [{ policyYear: 12, rate: 0.02 }, { policyYear: 17, rate: 0.02 }] },
            { premiumPaymentTerm: 18, bonusRate: [{ policyYear: 13, rate: 0.02 }, { policyYear: 18, rate: 0.02 }] },
            { premiumPaymentTerm: 19, bonusRate: [{ policyYear: 14, rate: 0.02 }, { policyYear: 19, rate: 0.02 }] },
            { premiumPaymentTerm: 20, bonusRate: [{ policyYear: 10, rate: 0.02 }, { policyYear: 15, rate: 0.02 }, { policyYear: 20, rate: 0.02 }] },
            { premiumPaymentTerm: 21, bonusRate: [{ policyYear: 11, rate: 0.02 }, { policyYear: 16, rate: 0.02 }, { policyYear: 21, rate: 0.02 }] },
            { premiumPaymentTerm: 22, bonusRate: [{ policyYear: 12, rate: 0.02 }, { policyYear: 17, rate: 0.02 }, { policyYear: 22, rate: 0.02 }] },
            { premiumPaymentTerm: 23, bonusRate: [{ policyYear: 13, rate: 0.02 }, { policyYear: 18, rate: 0.02 }, { policyYear: 23, rate: 0.02 }] },
            { premiumPaymentTerm: 24, bonusRate: [{ policyYear: 14, rate: 0.02 }, { policyYear: 19, rate: 0.02 }, { policyYear: 24, rate: 0.02 }] },
            { premiumPaymentTerm: 25, bonusRate: [{ policyYear: 15, rate: 0.02 }, { policyYear: 20, rate: 0.02 }, { policyYear: 25, rate: 0.02 }] },
            { premiumPaymentTerm: 26, bonusRate: [{ policyYear: 16, rate: 0.02 }, { policyYear: 21, rate: 0.02 }, { policyYear: 26, rate: 0.02 }] },
            { premiumPaymentTerm: 27, bonusRate: [{ policyYear: 17, rate: 0.02 }, { policyYear: 22, rate: 0.02 }, { policyYear: 27, rate: 0.02 }] },
            { premiumPaymentTerm: 28, bonusRate: [{ policyYear: 18, rate: 0.02 }, { policyYear: 23, rate: 0.02 }, { policyYear: 28, rate: 0.02 }] },
            { premiumPaymentTerm: 29, bonusRate: [{ policyYear: 19, rate: 0.02 }, { policyYear: 24, rate: 0.02 }, { policyYear: 29, rate: 0.02 }] },
            { premiumPaymentTerm: 30, bonusRate: [{ policyYear: 15, rate: 0.03 }, { policyYear: 20, rate: 0.03 }, { policyYear: 25, rate: 0.03 }, { policyYear: 30, rate: 0.03 }] },
        ]

        const bonusRatesForPremiumTerm = allAccumulationBonusrate.find(rate => rate.premiumPaymentTerm === premiumPaymentTerm).bonusRate;
        const bonusRateForCurrentYear = bonusRatesForPremiumTerm.find(rate => rate.policyYear === currentPolicyYear);

        // console.log("bonusRateForCurrentYear: ", bonusRateForCurrentYear);

        return bonusRateForCurrentYear ? bonusRateForCurrentYear.rate : 0;   // 0.02 means 2% bonus of accumulation account value, 0.03 bonus of accumulation account value, 0 means no bonus
    }

    // ===
    // * Charges
    // ===

    const getInitialAccountCharges = (premiumPaymentTerm, investmentYear) => {

        const createInitialAccountCharges = (charges) => charges.map((rate, i) => { return { policyYear: i + 1, rate: rate } });

        const getInitialAccountCharges = [
            { premiumPaymentTerm: 10, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06]) },

            { premiumPaymentTerm: 11, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055]) },
            { premiumPaymentTerm: 12, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055]) },
            { premiumPaymentTerm: 13, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 14, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 15, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055]) },

            { premiumPaymentTerm: 16, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 17, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 18, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 19, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055]) },
            { premiumPaymentTerm: 20, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055]) },

            { premiumPaymentTerm: 21, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04]) },
            { premiumPaymentTerm: 22, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04]) },
            { premiumPaymentTerm: 23, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04]) },
            { premiumPaymentTerm: 24, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04]) },
            { premiumPaymentTerm: 25, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04]) },

            { premiumPaymentTerm: 26, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04, 0.035]) },
            { premiumPaymentTerm: 27, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04, 0.035, 0.035]) },
            { premiumPaymentTerm: 28, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04, 0.035, 0.035, 0.035]) },
            { premiumPaymentTerm: 29, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04, 0.035, 0.035, 0.035, 0.035]) },
            { premiumPaymentTerm: 30, annualCharge: createInitialAccountCharges([0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.06, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.055, 0.04, 0.04, 0.04, 0.04, 0.04, 0.035, 0.035, 0.035, 0.035, 0.035]) },
        ]

        const chargesForPremiumTerm = getInitialAccountCharges.find(charges => charges.premiumPaymentTerm === premiumPaymentTerm).annualCharge;

        return (investmentYear > chargesForPremiumTerm.length)
            ? 0 // no charges after the last premium payment year, no more inital account charges
            : chargesForPremiumTerm[investmentYear - 1]; // charges for the current year. minus 1 because the array is 0 based
    }

    const getAccumulationAccountCharges = (policyYear) => {

        // console.log("policyYear @ getAccumulationAccountCharges: ", policyYear);

        return policyYear >= 1 && policyYear <= 10
            ? 0.016
            : policyYear >= 11 && policyYear <= 20 ? 0.014 : 0.012; // 1.6% for 1st to 10th year, 1.4% for 11th to 20th year, 1.2% for 21st year onwards 
    }

    // ====
    // * 1st 24 months calculation (Initial Investment Account only)
    // ====

    const calculationForInitialAccountFirst24Months = (monthlyPremium, premiumPaymentTerm, fundPriceData, createExcel) => {

        let previousMonthEndUnitBalance_IUA = 0; // Initial Unit Account value at be 0 at Month 1

        // Booster Bonus = Booster Bonus rate x regular premium received in the first 24 months
        const boosterBonusRate = getBoosterBonusRate(monthlyPremium, premiumPaymentTerm); // 0.23 means 23% of the premium
        // console.log("boosterBonusRate:", boosterBonusRate);

        for (let i = 0; i < 24; i++) { // first 24 months

            let excelRow = [];

            // * At beginning of the month
            // Purchase units at the beginning of the month
            // Allocate welcome bonus units at the beginning of the month
            // Allocate customer propm bonus at the beginning of the month
            // Minus initial account charges at the beginning of the month

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // insert IUA monthly investment
            createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,###', align: "center" });
            totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);

            // insert cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,###', align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // excelRow.push({ type: String, value: date.format(currentMonthStartDate, 'DD/MM/YYYY', false), align: "center"});

            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: ", UTprice);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_IUA = previousMonthEndUnitBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // plus purchase of the units at the beginning of month
            const unitsPurchased = monthlyPremium / UTprice;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += unitsPurchased;
            // console.log("unitsPurchased: ", unitsPurchased);

            // plus welome bonus (booster units)
            const boosterUnits = monthlyPremium * boosterBonusRate / UTprice;
            createExcel && excelRow.push({ type: Number, value: boosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += boosterUnits;
            // console.log("boosterUnits: ", boosterUnits);

            // plus cusomter promo bonus (additional booster)
            const additonalBoosterUnits = monthlyPremium * getAdditionalBoosterBonusRate(monthlyPremium, premiumPaymentTerm) / UTprice;
            createExcel && excelRow.push({ type: Number, value: additonalBoosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += additonalBoosterUnits;
            // console.log("additonalBoosterUnits: ", additonalBoosterUnits);

            // minus initial account charges
            // const currentPolicyYear = Math.floor(i / 12) + 1; // i + 1 because the array is 0 based
            const initialAccountCharges = getInitialAccountCharges(premiumPaymentTerm, Math.floor(i / 12) + 1); // i + 1 because the array is 0 based
            const monthlyInitialAccountCharges_Units = initialAccountCharges.rate / 12 * openingBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: monthlyInitialAccountCharges_Units * -1, format: '#,##0.000000', align: "center" });
            openingBalance_IUA -= monthlyInitialAccountCharges_Units;
            // console.log("monthlyInitialAccountCharges_Units: ", monthlyInitialAccountCharges_Units);

            // * At end of month
            // month end date 
            const currentMonthEndDate = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // units at end of month (IUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("endingBalance_IUA: ", openingBalance_IUA);

            const value_IUA = openingBalance_IUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '#,##0.000000', align: "center" });
            // console.log("value_IUA: ", value_IUA);
            // console.log("=======");

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // filling the month index and date for Accumulation Unit Account (AUA)
            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // insert AUA monthly investment
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // insert cumulative premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // calculate the date of the current month
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            // insert the UT price at the beginning of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // filling the rest of the AUA columns with 0
            const zerosToAdd = 6;
            for (let j = 0; j < zerosToAdd; j++) {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" }); // adding 0 to the remaining columns (AUA)        
            }

            // ut price at the end of the month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // units at end of month (AUA end units)
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });

            // value at end of month (AUA value)
            createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" });

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // total value IUA + AUA, AUA value is 0 at the end of 24 months
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '$#,##0.000000', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // storing IUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_IUA = openingBalance_IUA;

        } // end for loop for 1st

        return previousMonthEndUnitBalance_IUA;
    }

    // ====
    // * From 25th month to end of premium payment term (Initial Investment Account + Accumulation Account)
    // ====

    const calculationTillEndPremiumTerm = (monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, unitBalance_IUA, createExcel) => {

        let previousMonthEndUnitBalance_IUA = unitBalance_IUA;
        let previousMonthEndUnitBalance_AUA = 0; // Accumulation Unit Account value at be 0 at Month 1

        const month_preimumPaymentTerm = +(premiumPaymentTerm * 12).toFixed(0);
        const month_InvestmentPeriod = +(investmentPeriod * 12).toFixed(0);

        for (let i = 24; i < month_InvestmentPeriod; i++) { // from 25th month to end of premium payment term

            let excelRow = [];

            // ===
            // * Investment Unit Account (IUA) calculation section
            // * At beginning of the month
            // No purchas of units in IUA after 24 months
            // No welcome bonus units after 24 months
            // No allocate customer propm bonus after 24 months
            // Minus initial account charges at the beginning of the month
            // ===

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });

            // insert IUA monthly investment
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // insert cumulative premium
            createExcel && excelRow.push({ type: Number, value: 0, align: "center" });

            // calculate the date of the current month
            const currentMonthStartDate = date.addMonths(currentMonth1st, i);
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthStartDate: ", currentMonthStartDate.toString());

            let UTprice = fundPriceData[i];
            createExcel && excelRow.push({ type: Number, value: fundPriceData[i], format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month start: **** ", fundPriceData[i]);

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_IUA = previousMonthEndUnitBalance_IUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("openingBalance_IUA: ", openingBalance_IUA);

            // no purchase of the units 
            const unitsPurchased = 0;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += unitsPurchased;
            // console.log("unitsPurchased: ", unitsPurchased);

            // no welome bonus (booster units)
            const boosterUnits = 0
            createExcel && excelRow.push({ type: Number, value: boosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += boosterUnits;
            // console.log("boosterUnits: ", boosterUnits);

            // no cusomter promo bonus (additional booster)
            const additonalBoosterUnits = 0;
            createExcel && excelRow.push({ type: Number, value: additonalBoosterUnits, format: '#,##0.000000', align: "center" });
            openingBalance_IUA += additonalBoosterUnits;
            // console.log("additonalBoosterUnits: ", additonalBoosterUnits);

            // minus initial account charges only if withing premium payment term

            if (i < month_preimumPaymentTerm) {
                // const currentPolicyYear = Math.floor(i / 12) + 1; // i + 1 because the array is 0 based
                const initialAccountCharges = getInitialAccountCharges(premiumPaymentTerm, Math.floor(i / 12) + 1); // i + 1 because the array is 0 based
                const monthlyInitialAccountCharges_Units = initialAccountCharges.rate / 12 * openingBalance_IUA;
                createExcel && excelRow.push({ type: Number, value: monthlyInitialAccountCharges_Units * -1, format: '#,##0.000000', align: "center" });
                openingBalance_IUA -= monthlyInitialAccountCharges_Units;
                // console.log("monthlyInitialAccountCharges_Units: ", monthlyInitialAccountCharges_Units);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, format: '#,##0.000000', align: "center" }); // no charges after premium payment term
            }

            // * At end of month
            // month end date
            const currentMonthEndDate = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate, format: 'dd/mm/yyyy', align: "center" });
            // console.log("currentMonthEndDate: ", currentMonthEndDate.toString());

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });
            // console.log("UTprice at month end: ", UTprice);

            // units at end of month (IUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_IUA, format: '#,##0.000000', align: "center" });
            // console.log("endingBalance_IUA: ", openingBalance_IUA);

            // value of IUA at the end of the month
            const value_IUA = openingBalance_IUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_IUA, format: '$#,##0.000000', align: "center" });
            // console.log("value_IUA: ", value_IUA);

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // storing IUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_IUA = openingBalance_IUA;

            // ===
            // Accumulation Unit Account (AUA) calculation
            // * At beginning of the month
            // Minus accuulation account charges at the beginning of the month
            // Plus Loyalty Bonus at the end of every policy year (from the end of 3rd policy year till end of investment period). Use function => getLoyaltyBonusRate
            // Plus Accumulation Bonus at the end of specified policy years based on premium payment term. Use function => getAccumulationBonusRate
            // ===

            // insert the month index
            createExcel && excelRow.push({ type: Number, value: i + 1, align: "center" });


            // insert AUA monthly investment

            if (i < month_preimumPaymentTerm) {
                createExcel && excelRow.push({ type: Number, value: monthlyPremium, format: '$#,###', align: "center" });
                totalInvestedPremium = Math.round(totalInvestedPremium + monthlyPremium);
            } else {
                createExcel && excelRow.push({ type: Number, value: 0, align: "center" });
            }

            // insert cumulative premium
            createExcel && excelRow.push({ type: Number, value: totalInvestedPremium, format: '$#,###', align: "center" });

            // calculate the date of the current month
            createExcel && excelRow.push({ type: Date, value: currentMonthStartDate, format: 'dd/mm/yyyy', align: "center" });

            // insert the UT price at the beginning of the month
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // opening balace = previous month end value (monthEndValue)
            let openingBalance_AUA = previousMonthEndUnitBalance_AUA;
            createExcel && excelRow.push({ type: Number, value: openingBalance_AUA, format: '#,##0.000000', align: "center" });

            // purchase of the units in AUA if premium payment term is not over
            UTprice = fundPriceData[i];
            // console.log("UTprice: ", UTprice);

            const unitsPurchased_AUA = ((i + 1) / 12) <= premiumPaymentTerm ? monthlyPremium / UTprice : 0;
            createExcel && excelRow.push({ type: Number, value: unitsPurchased_AUA, format: '#,##0.000000', align: "center" });
            openingBalance_AUA += unitsPurchased_AUA;
            // console.log("unitsPurchased_AUA: ", unitsPurchased_AUA);

            // minus accumulation account charges
            const accumulationAccountCharges = getAccumulationAccountCharges(Math.floor(i / 12) + 1);
            // console.log("accumulationAccountCharges: ", accumulationAccountCharges);
            const monthlyAccumulationAccountCharges_Units = accumulationAccountCharges / 12 * openingBalance_AUA;
            // console.log("monthlyAccumulationAccountCharges_Units: ", monthlyAccumulationAccountCharges_Units);
            createExcel && excelRow.push({ type: Number, value: monthlyAccumulationAccountCharges_Units * -1, format: '#,##0.000000', align: "center" });
            openingBalance_AUA -= monthlyAccumulationAccountCharges_Units;

            // plus Loyalty Bonus at the end of every policy year (from the end of 3rd policy year till end of investment period)
            const loyaltyBonusRate = (i >= 24 && (i + 1) % 12 === 0) ? getLoyaltyBonusRate(Math.floor(i / 12) + 1, premiumPaymentTerm) : 0;
            const loyaltyBonus = openingBalance_AUA * loyaltyBonusRate;
            createExcel && excelRow.push({ type: Number, value: loyaltyBonus, format: '#,##0.000000', align: "center" });
            // console.log("loyaltyBonus: ", loyaltyBonus);

            // plus Accumulation Bonus at the end of specified policy years based on premium payment term, from the end of 10th policy year till end of investment period
            const accumulationBonusRate = (i >= 100 && (i + 1) % 12 === 0) ? getAccumulationBonusRate(premiumPaymentTerm, Math.floor(i / 12) + 1) : 0;
            const accumulationBonus = openingBalance_AUA * accumulationBonusRate;
            createExcel && excelRow.push({ type: Number, value: accumulationBonus, format: '#,##0.000000', align: "center" });
            // console.log("accumulationBonus: ", accumulationBonus);

            // adding loyalty bonus and accumulation bonus to the opening balance
            openingBalance_AUA += loyaltyBonus + accumulationBonus;

            // * At end of month
            // month end date
            const currentMonthEndDate_AUA = date.addDays(date.addMonths(currentMonthStartDate, 1), -1);
            createExcel && excelRow.push({ type: Date, value: currentMonthEndDate_AUA, format: 'dd/mm/yyyy', align: "center" });

            // UT price at the end of current month
            UTprice = fundPriceData[i + 1];
            createExcel && excelRow.push({ type: Number, value: UTprice, format: '$#,##0.000000', align: "center" });

            // units at end of month (AUA end units)
            createExcel && excelRow.push({ type: Number, value: openingBalance_AUA, format: '#,##0.000000', align: "center" });

            // value of AUA at the end of the month
            const value_AUA = openingBalance_AUA * UTprice;
            createExcel && excelRow.push({ type: Number, value: value_AUA, format: '$#,##0.000000', align: "center" });

            // add empty cell
            createExcel && excelRow.push({ type: String, value: '', align: "center" });

            // total value IUA + AUA
            createExcel && excelRow.push({ type: Number, value: value_IUA + value_AUA, format: '$#,##0.000000', align: "center" });

            // store the excel row
            createExcel && excelData.push(excelRow);

            // storing AUA current end month value as the beginning IUA value of the next month
            previousMonthEndUnitBalance_AUA = openingBalance_AUA;

        } // investment account calculation loop

        return { IUA: previousMonthEndUnitBalance_IUA, AUA: previousMonthEndUnitBalance_AUA };
    }


    // ====
    // 1st 24 month: Initial Investment Account only
    // ====
    let previousMonthEndUnitBalance_IUA = calculationForInitialAccountFirst24Months(monthlyPremium, premiumPaymentTerm, fundPriceData, createExcel);
    // console.log("Initial Investment Account period of 24 months:", previousMonthEndUnitBalance_IUA);

    // ====
    // 25th month  onwards: Initial Investment Account + Accumulation Account
    // ====

    const { IUA, AUA } = calculationTillEndPremiumTerm(monthlyPremium, premiumPaymentTerm, investmentPeriod, fundPriceData, previousMonthEndUnitBalance_IUA, createExcel); // in units
    // console.log("Initial Investment Account :", IUA);
    // console.log("Accumulation Account :", AUA);

    // calculate total and format totalValue into dollars
    let totalValue = (IUA + AUA) * fundPriceData[fundPriceData.length - 1];
    // totalValue = totalValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','); // string in the format $#,##0.00
    // console.log(`Total Value: ${totalValue}`);

    return { insurer: "FWD", planName: "Invest First Max", availablity: true, totalInvestedPremium, IUA, AUA, totalValue, excelFileName, excelSchema: createExcelSchema(), excelData };

} // end investFirstMax

export default investFirstMax;
import React, { useState, useRef, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';

// PrimeReact 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';

// context
import { DispatchUserContext } from 'contexts/userContext';

// custom components
import LoadingScreen from 'components/LoadingScreen';

// custom method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import primeMesagesErrorCatcher from 'helpers/primeMesagesErrorCatcher';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';

// formik initial values
const initialValues = {
    email: "",
    password: "",
    invitationCode: "",
    rnfName: "",
    rnf: "",
    name: "",
    mobile: "",
    confirmedPassword: ""
}

// regex to check for 8 digits numbers starts with 6,8 & 9
const phoneRegExp = /^[689]\d{7}$/;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

// svg background image from https://bgjar.com

// component
const LoginRegister = () => {

    // Ref for messages
    const messages = useRef(null)

    // state to define loginMode (true) or registration mode (false)
    const [loginMode, setLoginState] = useState(true);
    const [loading, setLoading] = useState(false);

    // context dispatch function to update user context
    const dispatch = useContext(DispatchUserContext);

    // hook to navigate to other page
    const navigate = useNavigate();

    // custom hook
    const axiosInstance = useAxiosAuth();

    // formilk schema
    const validationSchema = yup.object({
        email: yup.string().email('Invalid email format').required('Email is required'),
        password: loginMode
            ? yup.string().required('Password is required')
            : yup.string().required('Password is required').matches(passwordRegExp, "Your password must contain at least 8 characters with 1 uppercase, 1 lowercase, 1 number and 1 special character"),
        // conditionally add on schema if not login mode    
        ...(!loginMode && {
            invitationCode: yup.string().required('Regstration code is required'),
            rnfName: yup.string().required('RNF name is required'),
            rnf: yup.string().required('RNF name is required'),
            name: yup.string().required('Your preferred name is required'),
            mobile: yup.string().required('Your mobile number is required').matches(phoneRegExp, 'Mobile number is not valid'),
            confirmedPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password')], 'Passwords do not match'),
        }),
    });

    // formik instance
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    });

    // function to handle toggle between login mode and registration mode
    const handleChangeMode = () => setLoginState(prev => !prev);

    // function to handle formik form submit
    const handleSubmit = async (values) => {

        // display loading screen
        setLoading(true);

        // remove all empty fields
        Object.keys(values).forEach(key => (values[key] === "") && delete values[key]);

        if (loginMode) {
            // backend logic to check user id & password for login
            try {
                const resLogin = await axiosInstance.post('non-auth/login', values);
                // console.log(resLogin)

                const { validLogin, id } = resLogin?.data;
                if (validLogin) {
                    // valid user email and password
                    dispatch({ type: "VALID_USER", value: id });
                } else {
                    // invalid user email and password
                    messages.current.show({ severity: 'error', detail: `Error : Invalid email or password`, sticky: true })
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                primeMesagesErrorCatcher(error, messages);
            }
        } else {
            // backend logic to register a user
            try {
                const resReg = await axiosInstance.post('non-auth/registration', values);

                // new user is created, clear form, update user context
                if (resReg.status === 201 && resReg?.data?.success) {
                    formik.resetForm();
                    const { success, ...value } = resReg?.data;
                    setLoading(false);
                    dispatch({ type: "SETUP_2FA", value });
                } else {
                    // invalid user email and password
                    setLoading(false);
                    messages.current.show({ severity: 'error', detail: `Error : Invalid registration code or email`, sticky: true })
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                primeMesagesErrorCatcher(error, messages);
            }
        }
    }

    // function to nagivate to forget password page
    const handleForgetPassword = () => {
        navigate("/forgetPassword");
    }

    return (
        <div className="flex">
            <div className="surface-section h-screen w-full md:w-6 p-6 md:p-8">

                <div className="mb-5">
                    <div className="text-900 text-3xl font-medium mb-2">{loginMode ? "Welcome Back" : "Registration"}</div>
                    <span className="text-600 font-medium mr-2">{loginMode ? "Enter your credentials" : "All fields are required"}</span>
                </div>

                <Messages ref={messages} />

                <div>
                    {
                        !loginMode &&
                        <>
                            {displayFormikLabel("invitationCode", "Your Invitation Code", formik.touched, formik.errors)}
                            <InputText id="invitationCode" type="text" value={formik.values.invitationCode} onChange={formik.handleChange} className="w-full mb-2" />

                            {displayFormikLabel("rnfName", "Full Name as per RNF", formik.touched, formik.errors)}
                            <InputText id="rnfName" type="text" value={formik.values.rnfName} onChange={formik.handleChange} className="w-full mb-2" />

                            {displayFormikLabel("rnf", "Please enter your RNF number", formik.touched, formik.errors)}
                            <InputText id="rnf" type="text" value={formik.values.rnf} onChange={formik.handleChange} className="w-full mb-2" />

                            {displayFormikLabel("name", "Preferred Name", formik.touched, formik.errors)}
                            <InputText id="name" type="text" value={formik.values.name} onChange={formik.handleChange} className="w-full mb-2" />

                            {displayFormikLabel("mobile", "Mobile (E.g. 91234567)", formik.touched, formik.errors)}
                            <InputText id="mobile" type="text" value={formik.values.mobile} onChange={formik.handleChange} className="w-full mb-2" />
                        </>
                    }

                    {displayFormikLabel("email", "Work Email", formik.touched, formik.errors)}
                    <InputText id="email" type="text" value={formik.values.email} onChange={formik.handleChange} className="w-full mb-2" />

                    {displayFormikLabel("password", "Password", formik.touched, formik.errors)}
                    <InputText id="password" type="password" value={formik.values.password} onChange={formik.handleChange} className="w-full mb-2" />

                    {
                        !loginMode &&
                        <>
                            {displayFormikLabel("confirmedPassword", "Confirm Password", formik.touched, formik.errors)}
                            <InputText id="confirmedPassword" type="password" value={formik.values.confirmedPassword} onChange={formik.handleChange} className="w-full mb-2" />
                        </>
                    }
                </div>
                <div className="flex mt-5 mb-1">
                    <Button type="Submit" label={loginMode ? "Sign In" : "Register"} icon="pi pi-user" className="w-full" onClick={formik.handleSubmit} />
                </div>

                <div className="flex align-items-center justify-content-end mb-2">
                    <Button label={loginMode ? "Register" : "Back to sign In"} className="p-button-info p-button-text" onClick={handleChangeMode} />
                    |
                    <Button label="Forgot password?" className="p-button-info p-button-text" onClick={handleForgetPassword} />
                </div>
            </div>
            <div className="hidden md:block w-6 bg-no-repeat bg-cover" style={{ backgroundImage: "url('assets/images/sign-in.svg')" }}></div>
            {loading && <LoadingScreen />}
        </div>
    )
}
export default LoginRegister
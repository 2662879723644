import React, { useState, useEffect, useContext, useCallback, Suspense } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

// user context
import { UserContext } from 'contexts/userContext';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayProductDetails from '_dashboard/modules/comparison/DisplayProductDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom helper method 
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import formatDollar from 'helpers/formatDollar';
import scrollToTop from 'helpers/scrollToTop';

// multiPayCI comparison insurers
import { insurer_multiPayCI, userContext_multiPayCI } from './comparisonData';

// dropdown values
const term = [
    { label: 'To Around Age 99/100', term: "PayToMax" },
    { label: 'To Around Age 85', term: "PayTo85" },
    { label: 'To Around Age 75', term: "PayTo75" },
    { label: 'To Around Age 70', term: "PayTo70" },
    { label: 'To Around Age 65', term: "PayTo65" },
    { label: '30 Years', term: "30Yrs" },
    { label: '25 Years', term: "25Yrs" },
    { label: '20 Years', term: "20Yrs" },
];

const sumInsured = [
    { label: '$250,000', amt: 250000 },
    { label: '$200,000', amt: 200000 },
    { label: '$150,000', amt: 150000 },
    { label: '$100,000', amt: 100000 },
    { label: '$50,000', amt: 50000 },
];

const termList = [
    { label: 'To Around Age 99/100', term: "PayToMax", key: "payToMax" },
    { label: 'To Around Age 85', term: "PayTo85", key: "payTo85" },
    { label: 'To Around Age 75', term: "PayTo75", key: "payTo75" },
    { label: 'To Around Age 70', term: "PayTo70", key: "payTo70" },
    { label: 'To Around Age 65', term: "PayTo65", key: "payTo65" },
    { label: '30 Years', term: "30Yrs", key: "30Yrs" },
    { label: '25 Years', term: "25Yrs", key: "25Yrs" },
    { label: '20 Years', term: "20Yrs", key: "20Yrs" },
];

const sumInsuredList = [
    { label: '$250,000', amt: 250000, key: "sa250k" },
    { label: '$200,000', amt: 200000, key: "sa200k" },
    { label: '$150,000', amt: 150000, key: "sa150k" },
    { label: '$100,000', amt: 100000, key: "sa100k" },
    { label: '$50,000', amt: 50000, key: "sa50k" },
];

// formik
const initialValues = {
    sumInsured: 100000,
    sumInsuredArray: [100000],
    term: "PayTo75",
    termArray: ["PayTo65", "PayTo70", "PayTo75"],
}

// component
const CompareMultiCI = () => {

    // state
    const [activeTabIndex, setActiveTabIndex] = useState(0); // 0 for search by term, 1 for search by sum insured
    const [query, setQuery] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [loading, setLoading] = useState(false);

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width]);

    useEffect(() => {
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps    

    // to activate after form is completed
    useEffect(() => {

        const getData = async () => {
            setLoading(true);
            try {
                const res = await axiosInstance.post('dashboard/comparison/multiPayCI', query);
                if (res.data) {
                    // console.log(res.data);
                    let premiumArr = res.data.result[0]; // array that contains all premium data point from mongo
                    const productArr = res.data.result[1];  // array that contains all product info for this category 

                    // sort premiumArr by premium (descending) follow by insurer name (ascending) name
                    premiumArr = sortArrayOfObjectsByKey(premiumArr, "type", "desc");
                    premiumArr = sortArrayOfObjectsByKey(premiumArr, "insurer", "asc");

                    const combineArrData = premiumArr.map(entry => {

                        // getting the insurer & plan name to match the corresponding product info
                        const insurer = entry.insurer.trim();
                        const plan = entry.planName.trim();

                        // to find the corresponding
                        const entryInfo = productArr.filter(p => p.insurerName === insurer && p.planName === plan)// end filter

                        return entryInfo.length > 0
                            ? { ...entry, details: entryInfo[0], promo: entryInfo[0]?.customerPromo?.valid }
                            : { ...entry, details: {}, promo: false, }

                    }) // end map
                    //console.log(combineArrData);

                    const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_multiPayCI)?.onlyDisplayInsurers;
                    // console.log("allowedInsurers", allowedInsurers);

                    // filter out the insurers that are not allowed to be displayed
                    // of the allowedInsurers is empty, display all insurers
                    (allowedInsurers && allowedInsurers.length > 0)
                        ? setDisplayData(() => combineArrData.filter(ele => allowedInsurers.includes(ele.insurer)))
                        : setDisplayData(() => combineArrData); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

                    // close loading screen
                    setLoading(false);
                }
            } catch (error) {
                // console.log(error);
                setLoading(false);
            }
        }
        query.anb && getData();

    }, [axiosInstance, navigate, query, user])

    // formik validator with yup to check the value is not an empty string array based on active tab index
    const validationSchema = yup.object(
        (activeTabIndex === 0)
            ? {
                sumInsuredArray: yup.array().test('is-not-empty', 'Please select at least one sum insured amount', value => value.length > 0),
                term: yup.string().required("Please select a term")
            }
            : {
                termArray: yup.array().test('is-not-empty', 'Please select at least one term', value => value.length > 0),
                sumInsured: yup.number().typeError("Please select a sum insured amount").required("Please select a sum insured amount")
            }
    );

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = (values) => {
        
        // clear current display table and show loading screen
        setDisplayData(() => []);
        // console.log("handleSubmit", values, user);

        // add current age to query inputs
        setQuery({
            anb: user?.comparisonInsured?.ANB,
            ageNearestBirthday: user?.comparisonInsured?.AnB,
            gender: user?.comparisonInsured?.gender?.charAt(0),
            smoker: user?.comparisonInsured?.smokingStatus === "Non-Smoker" ? "NS" : "S",
            ...(activeTabIndex === 0)
                ? { searchType: "term", sumInsured: values.sumInsuredArray, term: [values.term], }
                : { searchType: "sumInsured", sumInsured: [values.sumInsured], term: values.termArray, }
        });
        setReadyToSearch(false);
        setShowEditButton(true);
        scrollToTop();
    }

    const handleReset = () => {
        setDisplayData([]);

        /*
            sumInsured: 100000,
            sumInsuredArray: [100000],
            term: "PayTo75",
            termArray: ["PayTo65", "PayTo70", "PayTo75"],
        
        
        */
        // formik.handleReset();
        formik.setFieldValue("sumInsuredArray", []);
        formik.setFieldValue("term", "");
        formik.setFieldValue("termArray", []);
        formik.setFieldValue("sumInsured", null);
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    const handleEdit = () => {
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    // checkbox formilk functions
    // check if the box's value is selected based on formik values (formik.values.sumInsuredArray)
    const isChecked = amt => formik.values.sumInsuredArray.some((item) => item === amt);

    // check if the select amount is in the list
    const handleListChange = amt => formik.values.sumInsuredArray.some((item) => item === amt)
        ? formik.setFieldValue("sumInsuredArray", formik.values.sumInsuredArray.filter((item) => item !== amt)) // if yes, remove from list
        : formik.setFieldValue("sumInsuredArray", [...formik.values.sumInsuredArray, amt]); // if no, add to list

    const generateComparison = useCallback(() => {

        // functions to format data in columns
        const formatSA = (value) => "$" + formatDollar(value.sumInsured);
        const formatPremiumTerm = (value) => value.policyTerm + " Yrs";
        const formatCurrency = (value) => value.premium.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatPromo = (value) => value.promo ? "Y" : "";
        const formatMaxClaim = (value) => `${value.maxClaim}X`;

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
            </div>
        </>

        const rowExpansionTemplate = (data) => <DisplayProductDetails data={data} />

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Multipay Critical Illness Insurance" />
                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        // footer
                        // footer={<DataTableFooter type="multiPayCI" query={query} />}

                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"
                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer_shortName" header="Insurer" sortable></Column>
                        <Column field="plan_shortName" header="Plan" sortable></Column>
                        <Column field="maxClaim" header="Max" body={formatMaxClaim} sortable></Column>
                        <Column field="type" header="Type" sortable></Column>
                        <Column field="sumInsured" header="Insured Amt" body={formatSA} sortable></Column>
                        <Column field="policyTerm" header="Policy Term" body={formatPremiumTerm} sortable></Column>
                        <Column field="waiver" header="Waiver" sortable></Column>
                        <Column field="premium" header="Annual Premium" body={formatCurrency} sortable></Column>
                        <Column field="promo" header="Promo" body={formatPromo} sortable></Column>
                    </DataTable>
                </Suspense>
            </div>
        </div>

    }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, query]);

    // console.log("@CompareMultiCI query", query);

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                        <TabPanel header="By Policy Term" disabled={!readyToSearch}>

                            <h5>Select coverage term</h5>
                            {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.term}</p>}
                            <Dropdown value={formik.values.term} disabled={!readyToSearch} options={term}
                                onChange={e => formik.setFieldValue("term", e.value)} optionLabel="label" optionValue="term" className='w-full' />

                            <h5>Select coverage amount</h5>
                            {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.sumInsuredArray}</p>}
                            {sumInsuredList.map((sa, index) => {
                                return (
                                    <div key={sa.key} className="field-checkbox">
                                        <Checkbox
                                            inputId={sa.key}
                                            value={sa.amt}
                                            checked={isChecked(sa.amt)}
                                            disabled={!readyToSearch}
                                            onChange={e => handleListChange(e.value)} />
                                        <label htmlFor={sa.key} className="ml-2">
                                            {sa.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </TabPanel>
                        <TabPanel header="By Sum Insured" disabled={!readyToSearch}>
                            <h5>Select sum insured</h5>
                            {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.sumInsured}</p>}
                            <Dropdown
                                value={formik.values.sumInsured}
                                options={sumInsured}
                                disabled={!readyToSearch}
                                onChange={e => formik.setFieldValue("sumInsured", e.value)}
                                optionLabel="label"
                                optionValue="amt"
                                className='w-full' />
                            <h5>Select policy term</h5>
                            {(formik.touched && formik.errors) && <p className='p-error block font-medium'>{formik.errors.termArray}</p>}
                            {termList.map((t, index) => {
                                return (
                                    <div key={t.key} className="field-checkbox">
                                        <Checkbox
                                            inputId={t.key}
                                            value={t.term}
                                            checked={formik.values.termArray.some((item) => item === t.term)}
                                            disabled={!readyToSearch}
                                            onChange={e => formik.values.termArray.some((item) => item === t.term)
                                                ? formik.setFieldValue("termArray", formik.values.termArray.filter((item) => item !== t.term))
                                                : formik.setFieldValue("termArray", [...formik.values.termArray, t.term])} />
                                        <label htmlFor={t.key} className="ml-2">
                                            {t.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </TabPanel>
                    </TabView>
                    <hr></hr>
                    <div className='grid mt-3'>
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                            <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                        </div>
                        {
                            showEditButton && <div className='col-12 md:col-4'>
                                <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                            </div>
                        }
                        <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                            <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
                <FormInsurerDisplayedOption comparisonType={userContext_multiPayCI} insurerList={insurer_multiPayCI} />
            </div>
            {loading && <LoadingScreen />}
        </div>
    )
}

export default CompareMultiCI
import React, { useContext, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from "react-router-dom";

import DashboardBreadcrumb from './DashboardBreadcrumb';
import DashboardMenu from './DashboardMenu';
import DashboardConfig from './DashboardConfig';

// user context
import { UserContext } from 'contexts/userContext';
import { DispatchUserContext } from 'contexts/userContext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const MINUTE_MS = 60000;

const DasboardTopbar = (props) => {

    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });

    // state to store time to auto logout
    const [logoutInMins, setLogoutInMins] = useState(121);
    const [displayDialog, setDisplayDialog] = useState(false);

    // user context
    const user = useContext(UserContext);
    const dispatch = useContext(DispatchUserContext)

    // avatar and profile name to be displayed on the top bar
    const avatar = user?.userDetails?.avatarURL[0] || "assets/images/avatar.png";
    const profileName = user?.userDetails?.name || "Preferred Name"

    // navigate to other pages
    const navigate = useNavigate();
    const nav = (path) => navigate(path);

    const logout = () => {
        localStorage.clear();
        dispatch({ type: "LOGOUT" });
        navigate("/");
    }

    // auto logout feature and display time to auto logout
    useEffect(() => {
        const interval = setInterval(() => {

            // calculate the time left for auto logout
            const sessionLogoutInMins = Math.floor((user?.sessionEndsAt - Date.now()) / 1000 / 60);
            // console.log("sessionLogoutInMins", sessionLogoutInMins);
            
            if (sessionLogoutInMins <= 0) {
                logout();
            } else {
                setLogoutInMins(sessionLogoutInMins);
                if (sessionLogoutInMins <= 3) {
                    setDisplayDialog(true);
                }
            }
            // console.log('Logs every minute');
        }, MINUTE_MS); // This interval function runs every minute

        // This unmount function, in which clears the interval to prevent memory leaks.
        return () => clearInterval(interval);

        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    return (
        <>
            <div className="layout-topbar">
                <div className="topbar-left">
                    <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                        <i className="pi pi-chevron-left"></i>
                    </button>

                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <DashboardBreadcrumb meta={props.meta} />
                    </div>
                </div>

                <DashboardMenu
                    model={props.menu}
                    menuMode={props.menuMode}
                    active={props.menuActive}
                    mobileMenuActive={props.staticMenuMobileActive}
                    onMenuClick={props.onMenuClick}
                    onMenuitemClick={props.onMenuitemClick}
                    onRootMenuitemClick={props.onRootMenuitemClick}
                ></DashboardMenu>

                <div className="layout-mask modal-in"></div>

                <div className="topbar-right">
                    <ul className="topbar-menu">
                        <li className={profileItemClassName}>
                            <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                                <img src={avatar} alt="diamond-layout" className="profile-image" />
                                <span className="profile-name">{profileName}</span>
                            </button>
                            <ul className="profile-menu fade-in-up">
                                <li>
                                    <button type="button" className="p-link" onClick={() => nav("/profile")}>
                                        <i className="pi pi-user"></i>
                                        <span>Update Profile</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={() => nav("/feedback")}>
                                        <i className="pi pi-comments"></i>
                                        <span>Feedback / Suggestion</span>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className="p-link" onClick={props.onConfigButtonClick}>
                                        <i className="pi pi-cog"></i>
                                        <span>Display Settings</span>
                                    </button>
                                </li>
                                <hr />
                                <li>
                                    <button type="button" className="p-link" onClick={logout}>
                                        <i className="pi pi-power-off"></i>
                                        <span>{'Logout'}</span>
                                    </button>
                                    <p className="text-xs text-right">{logoutInMins <= 120 ? `Auto log out in ${logoutInMins} min(s) ` : ""}</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <DashboardConfig
                configActive={props.configActive}
                menuMode={props.menuMode}
                onMenuModeChange={props.onMenuModeChange}
                colorScheme={props.colorScheme}
                changeColorScheme={props.changeColorScheme}
                inputStyle={props.inputStyle}
                onInputStyleChange={props.onInputStyleChange}
                menuTheme={props.menuTheme}
                changeMenuTheme={props.changeMenuTheme}
                componentTheme={props.componentTheme}
                changeComponentTheme={props.changeComponentTheme}
                onConfigClick={props.onConfigClick}
                onConfigButtonClick={props.onConfigButtonClick}
                rippleActive={props.ripple}
                onRippleChange={props.onRippleChange}
                uploadCurrentTheme={props.uploadCurrentTheme} // custom function to upload the current theme
            ></DashboardConfig>
            <Dialog visible={displayDialog} header={<div className="flex align-items-center">
                <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style={{ width: '32px', height: '32px' }}>
                    <i className="pi pi-info text-lg"></i>
                </span>
                <span className="font-medium text-2xl text-900">System Alert</span>
            </div>} onHide={() => setDisplayDialog(false)} footer={<div className=" border-top-1 surface-border pt-3">
                <Button onClick={() => setDisplayDialog(false)} label="Close" />
            </div >} modal breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }}>
                <p className="line-height-3 p-0 m-0">
                    {`System will auto logged out in ${logoutInMins} min(s)`}
                </p>
            </Dialog>
        </>
    );
};

export default DasboardTopbar;

import React, { useState } from 'react'

// prime react
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// prime react
import { Fieldset } from 'primereact/fieldset';

// 101 breakeven yields
import { breakEven_aiaProAchiever3 } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_AIA_ProAchiever3';
import { breakEven_eTiQaInvestSmartFlex } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_eTiQa_InvestSmartFlex';
import { breakEven_fwdInvestFirstMax } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_FWD_InvestFirstMax';
import { breakEven_fwdInvestGoalX } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_FWD_InvestGoalX';
import { breakEven_incomeInvestFlex } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_Income_investFlex';
import { breakEven_hsbcWealthVoyage } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_hsbc_wealthVoyage';
import { breakEven_singlifeSavvyInvest } from './type/ilp101Plans/breakEvenYield_singlife_savvyInvest_II';
import { breakEven_tmgoClassic } from '_dashboard/modules/comparison/type/ilp101Plans/breakEvenYield_TM_goClassic';


// helper function
const generateYields = (plan, filter) => {

  if (filter) { // this filter is added due to Singlife has "Fixed" and "Flexible" plan for Savvy Invest II
    plan = plan.filter(ele => ele.type === filter);
  }
  // console.table(plan);

  const result = [];
  for (let i = 0; i < plan.length; i++) {

    const { premiumPaymentTerm, monthlyPremium, breakEvenYield } = plan[i];
    // console.log(`index: ${i}, premiumPaymentTerm: ${premiumPaymentTerm}, monthlyPremium: ${monthlyPremium}, breakEvenYield: ${breakEvenYield}`);

    // check if this premium payment term is in result []
    const index = result.findIndex(ele => ele.premiumPaymentTerm === premiumPaymentTerm)
    // console.log("index", index);

    if (index >= 0) {
      result[index][`mthly${monthlyPremium}`] = monthlyPremium;
      result[index][`mthly${monthlyPremium}yield`] = breakEvenYield;
    } else {
      result.push({
        premiumPaymentTerm,
        [`mthly${monthlyPremium}`]: monthlyPremium,
        [`mthly${monthlyPremium}yield`]: breakEvenYield,
      });
    }
  }
  return result;
}

const formatYears = (value) => `${value.premiumPaymentTerm} Yrs`
const formatPercent = (value, monthlyPremium) => (value[`mthly${monthlyPremium}yield`] || value[`mthly${monthlyPremium}yield`] >= 0) ? `${value[`mthly${monthlyPremium}yield`]?.toFixed(2)}%` : "N.A."

// ====
// main function
// ====

const Display101ExtraInfo = (props) => {

  // console.log("Display101ExtraInfo props", props);

  const [planName, setPlanName] = useState("")
  const [yields, setYields] = useState([])
  const [display101BreakEvenYield, setDisplay101BreakEvenYield] = useState(false);

  const displayAllYield = (plan) => {

    setPlanName(plan);
    setDisplay101BreakEvenYield(true);

    let breakEvenYields = [] 
    if (plan === "AIA Pro Archiever 3") {
      breakEvenYields = generateYields(breakEven_aiaProAchiever3);
    } else if (plan === "eTiQa Invest Smart Flex") {
      breakEvenYields = generateYields(breakEven_eTiQaInvestSmartFlex);
    } else if (plan === "FWD Invest First Max") {
      breakEvenYields = generateYields(breakEven_fwdInvestFirstMax);
    } else if (plan === "FWD Invest Goal X") {
      breakEvenYields = generateYields(breakEven_fwdInvestGoalX);
    } else if (plan === "HSBC Wealth Voyage") {
      breakEvenYields = generateYields(breakEven_hsbcWealthVoyage);
    } else if (plan === "Income Invest Flex") {
      breakEvenYields = generateYields(breakEven_incomeInvestFlex);
    } else if (plan === "Singlife Savvy Invest II (Fixed)") {
      breakEvenYields = generateYields(breakEven_singlifeSavvyInvest, "Fixed");
    } else if (plan === "Singlife Savvy Invest II (Flexible)") {
      breakEvenYields = generateYields(breakEven_singlifeSavvyInvest, "Flexible");
    } else if (plan === "Tokio Marine #goClassic") {
      breakEvenYields = generateYields(breakEven_tmgoClassic);
    } else {
      breakEvenYields = [];
      console.log("error");
    }

    setYields(breakEvenYields);
  }

  const displayBreakEvenYield = () => <>
    <Fieldset legend="Break Even Yield" toggleable collapsed={true}>
      <div>
        <Button type="button" label="AIA Pro Archiever 3" aria-label="AIA Pro Archiever 3" className='block m-2' onClick={() => displayAllYield("AIA Pro Archiever 3")} />
        <Button type="button" label="eTiQa Invest Smart Flex" aria-label="eTiQa Invest Smart Flex" className='block m-2' onClick={() => displayAllYield("eTiQa Invest Smart Flex")} />
        <Button type="button" label="FWD Invest First Max" aria-label="FWD Invest First Max" className='block m-2' onClick={() => displayAllYield("FWD Invest First Max")} />
        <Button type="button" label="FWD Invest Goal X" aria-label="FWD Invest Goal X" className='block m-2' onClick={() => displayAllYield("FWD Invest Goal X")} />
        <Button type="button" label="HSBC Wealth Voyage" aria-label="HSBC Wealth Voyage" className='block m-2' onClick={() => displayAllYield("HSBC Wealth Voyage")} />
        <Button type="button" label="Income Invest Flex" aria-label="Income Invest Flex" className='block m-2' onClick={() => displayAllYield("Income Invest Flex")} />
        <Button type="button" label="Singlife Savvy Invest II (Fixed)" aria-label="Tokio Marine #goClassic" className='block m-2' onClick={() => displayAllYield("Singlife Savvy Invest II (Fixed)")} />
        <Button type="button" label="Singlife Savvy Invest II (Flexible)" aria-label="Tokio Marine #goClassic" className='block m-2' onClick={() => displayAllYield("Singlife Savvy Invest II (Flexible)")} />
        <Button type="button" label="Tokio Marine #goClassic" aria-label="Tokio Marine #goClassic" className='block m-2' onClick={() => displayAllYield("Tokio Marine #goClassic")} />
      </div>
    </Fieldset>
    <Dialog header={`Approximate Break Even Yield of ${planName}`} visible={display101BreakEvenYield} style={{ width: '80vw' }} onHide={() => setDisplay101BreakEvenYield(false)}>
      <ol className="mt-0 mb-4">
        <li>Premium payment is in monthly mode</li>
        <li>The figures below do not take into account any fund management charge</li>
        <li>The figures below do not take into account any cost of insurance charge</li>
        <li>All premiums due are paid until the end of the premium term without any partial withdrawals and no top up</li>
        <li>Customer promotion additional units are included for FWD, Income and Singlife</li>
      </ol>
      <div className="card flex justify-content-center flex-wrap">
        <DataTable value={yields} showGridlines tableStyle={{ minWidth: '50rem' }}>
          <Column field="premiumPaymentTerm" header="Premium Term" body={formatYears}></Column>
          <Column field="mthly300yield" header="$300 / mth" body={(value) => formatPercent(value, 300)}></Column>
          <Column field="mthly500yield" header="$500 / mth" body={(value) => formatPercent(value, 500)}></Column>
          <Column field="mthly1000yield" header="$1,000 / mth" body={(value) => formatPercent(value, 1000)}></Column>
          <Column field="mthly2000yield" header="$2,000 / mth" body={(value) => formatPercent(value, 2000)}></Column>
          <Column field="mthly3000yield" header="$3,000 / mth" body={(value) => formatPercent(value, 3000)}></Column>
          <Column field="mthly4000yield" header="$4,000 / mth" body={(value) => formatPercent(value, 4000)}></Column>
        </DataTable>
      </div>
    </Dialog>
  </>

  const displayPiInfo = () => <>
    <Fieldset legend="PI Generation" toggleable collapsed={true}>
      <div>
        <p>All PIs are generated based on monthly mode. The fund allocation for the following insurers is as follows.</p>
        <ol>
          <li>AIA Pro Achiever 3.0</li>
          <ul>
            <li>AIA Elite Adventurous</li>
          </ul>
          <li>eTiQa Invest Smart Flex</li>
          <ul>
            <li>There is no option to select fund in iConnect Portal</li>
          </ul>
          <li>FWD Invest First Max</li>
          <ul>
            <li>Fundsmith Equity Fund EUR Acc - 100% </li>
          </ul>
          <li>FWD Invest Goal X</li>
          <ul>
            <li>Fundsmith Equity Fund EUR Acc - 100% </li>
          </ul>

          <li>HSBC Life Wealth Elite</li>
          <ul>
            <li>Fundsmith Equity Fund Sicav (EUR) - 100%</li>
          </ul>
          <li>Income Invest Flex</li>
          <ul>
            <li>Global Equity Fund - 100%</li>
          </ul>
          <li>Singlife Savvy Invest II</li>
          <ul>
            <li>Fundsmith Equity Fund - 100%</li>
          </ul>
          <li>Tokio Marine #goClassic</li>
          <ul>
            <li>Fundsmith Equity Fund Acc GBP - 100%</li>
          </ul>
        </ol>
      </div>
    </Fieldset>
  </>

  return (
    <div className='card'>
      <h5>Info</h5>
      <br></br>
      {props?.growthType === "Custom" ? displayBreakEvenYield() : displayPiInfo()}
    </div>
  )
}

export default Display101ExtraInfo;
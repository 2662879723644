import React, { useState, useEffect, useContext, useCallback, Suspense } from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup'
import { useNavigate } from "react-router-dom";

// prime react
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// user context
import { UserContext } from 'contexts/userContext';

// custom hook
import useAxiosAuth from 'hooks/useAxiosAuth';
import useScreenSize from 'hooks/useScreenSize';

// custom component
import DisplayInsuredDetails from '_dashboard/modules/comparison/DisplayInsuredDetails';
import DisplayShieldDetails from '_dashboard/modules/comparison/DisplayShieldDetails';
import DisplayComparisonTitle from '_dashboard/modules/comparison/DisplayComparisonTitle';
import FormInsurerDisplayedOption from '_dashboard/modules/comparison/FormInsurerDisplayedOption';
import LoadingScreen from 'components/LoadingScreen';
import DataTableFooter from '_dashboard/modules/comparison/DataTableFooter';

// custom helper method 
import displayFormikLabel from 'helpers/displayFormikLabel'; // display label for valid and invalid field
import sortArrayOfObjectsByKey from 'helpers/sortArrayOfObjectsByKey';
import scrollToTop from 'helpers/scrollToTop';

// shield comparison insurers
import { insurer_integratedShield, userContext_integratedShield } from './comparisonData';

// formik
const initialValues = {
    residency: "SGPR", // "SGPR" OR "FR"
    ipWithRider: true,
    comparisonType: "Private_Max",
}

// select option
const residency = [
    { name: 'Singaporean & PR', value: "SGPR" },
    { name: 'Foreigner', value: "FR", disabled: true }
]

const ipWithOrWithoutRider = [
    { label: "IP + Rider", value: true },
    { label: "IP Only", value: false },
]

const ipWithoutRider = [
    { name: 'Private & Restructure Hosp', value: false, key: "Private" },
    { name: 'Restructure Hosp Ward A & Below', value: false, key: "GovWardA" },
    { name: 'Restructure Hosp Ward B1 & Below', value: false, key: "GovWardB" },
    { name: 'Restructure Hosp Ward C', value: false, key: "GovWardC" },
    { name: 'CPF Standard Plan', value: false, key: "GovStandard" }
]

const ipWithRider = [
    { name: 'Private & Restructure Hosp (Max Cover)', value: true, key: "Private_Max" },
    { name: 'Private & Restructure Hosp (All Options)', value: false, key: "Private_All" },
    { name: 'Restructure Hosp Ward A & Below (Max Cover)', value: false, key: "GovWardA_Max" },
    { name: 'Restructure Hosp Ward A & Below (All Options)', value: false, key: "GovWardA_All" },
    { name: 'Restructure Hosp Ward B1 & Below (Max Cover)', value: false, key: "GovWardB_Max" },
    { name: 'Restructure Hosp Ward B1 & Below (All Options)', value: false, key: "GovWardB_All" },
    { name: 'Restructure Hosp Ward C (Max Cover)', value: false, key: "GovWardC_Max" },
    { name: 'Restructure Hosp Ward C (All Options)', value: false, key: "GovWardC_All" },
    // { name: 'CPF Standard Plan', value: false, key: "GovStandard_All" }
]

// component
const CompareShield = () => {

    // user context
    const user = useContext(UserContext);

    // useEffect to check if insured details is in comparison context
    const navigate = useNavigate();

    // custom axios instance
    const axiosInstance = useAxiosAuth();

    // state
    const [withRider, setWithRider] = useState(true);
    const [query, setQuery] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [readyToSearch, setReadyToSearch] = useState(true);
    const [showEditButton, setShowEditButton] = useState(false); // to display Edit button if the search is done
    const [loading, setLoading] = useState(false);

    // data-table header states
    const [expandedRows, setExpandedRows] = useState(null);
    const [mobileScreen, setMobileScreen] = useState(false)
    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [filters2, setFilters2] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    // check screen size to decide what label to display
    const screenSize = useScreenSize();

    // https://www.paintcodeapp.com/news/ultimate-guide-to-iphone-resolutions (base on iphone 6+ 7+ & 8+)
    useEffect(() => {
        setMobileScreen(screenSize.width <= 600);
    }, [screenSize.width])

    useEffect(() => {
        if (!user.comparisonInsured) {
            return navigate("/comparison/insuredDetails", { state: "from_CompareTerm" });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps    

    // formik https://stackoverflow.com/questions/56241892/yup-compare-field-itself-with-another-field
    const validationSchema = yup.object({ comparisonType: yup.string().required("Please select a coverage type.") });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => handleSubmit(values)
    })

    // query backend whenever there is a change in inputs   
    useEffect(() => {
        const getShieldData = async () => {
            setLoading(true);
            try {
                const res = await axiosInstance.post('dashboard/comparison/shieldSingle', query);
                // console.log(res.data)

                if (res.data) {
                    let [data, productArr] = res?.data?.result;

                    // sort by planTier follow by insurer name
                    data = sortArrayOfObjectsByKey(data, "planTier", "asc");
                    data = sortArrayOfObjectsByKey(data, "insurer", "asc");

                    // add the shield info into the display array with the corresponding insurer
                    let mongoData = data.map(record => {
                        // record.info will store all the shield info and display in the expander
                        record.info = productArr.find(item => item?.insurerName === record?.insurer);
                        return {
                            ...record,
                            _id: record.uniqueID // making the _id unquie so the comparison table drop down can work
                        }
                    });
                    // console.log("result @ getShieldData in useEffect", result);

                    const allowedInsurers = user?.comparisonInsurerDisplaySettings.find(ele => ele.comparisonType === userContext_integratedShield)?.onlyDisplayInsurers;
                    // console.log("allowedInsurers", allowedInsurers);

                    // filter out the insurers that are not allowed to be displayed
                    // of the allowedInsurers is empty, display all insurers
                    (allowedInsurers && allowedInsurers.length > 0)
                        ? setDisplayData(() => mongoData.filter(ele => allowedInsurers.includes(ele.insurer)))
                        : setDisplayData(() => mongoData); // use the mongoData if allowedInsurers.length === 0 (display all insurers)

                    // close loading screen
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        }
        // console.log(query)
        query.residency && getShieldData();

    }, [axiosInstance, navigate, user, query]);


    const handleSubmit = async (values) => {
        // clear current display table and show loading screen
        setDisplayData(() => []);

        // add current age to query inputs
        setQuery({ ...values, anb: user?.comparisonInsured?.ANB });
        setReadyToSearch(false);
        setShowEditButton(true);
        scrollToTop();
    }

    // handleReset
    const handleReset = () => {
        // formik.resetForm();
        formik.setFieldValue("comparisonType", "");
        setReadyToSearch(true);
        setDisplayData([]);
        setShowEditButton(false);
    }

    const handleEdit = () => {
        setReadyToSearch(true);
        setShowEditButton(false);
    }

    const handleIpOption = (value) => {
        formik.setFieldValue("comparisonType", "")
        formik.setFieldValue("ipWithRider", value);
        setWithRider(value);
    }

    const generateComparison = useCallback(() => {

        // functions to format data in columns
        const formatMediSaveCurrency = (value) => value.premium_MediSave.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatCashCurrency = (value) => value.premium_Cash.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatTotalCurrency = (value) => value.premium_Total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        const formatPromo = (value) => value?.info?.customerPromo?.valid ? "Y" : "";

        // function to expand all and collapse all expanders
        const collapseAll = () => setExpandedRows(null);

        const expandAll = () => {
            let _expandedRows = {};
            displayData.forEach(p => _expandedRows[`${p._id}`] = true);
            setExpandedRows(_expandedRows);
        }

        // filter function for key search
        const onGlobalFilterChange2 = (e) => {
            const value = e.target.value;
            let _filters2 = { ...filters2 };
            _filters2['global'].value = value;

            setFilters2(_filters2);
            setGlobalFilterValue2(value);
        }

        const renderHeader = () => <>
            <div className="flex justify-content-between">
                <span>
                    <Button icon="pi pi-plus" label={mobileScreen ? "" : "Expand All"} onClick={expandAll} className="mr-2" />
                    <Button icon="pi pi-minus" label={mobileScreen ? "" : "Collapse All"} onClick={collapseAll} />
                </span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder={mobileScreen ? "Search" : "Keyword Search"}
                        className={mobileScreen ? "p-inputtext-sm" : ""}
                        value={globalFilterValue2}
                        onChange={onGlobalFilterChange2}
                    />
                </span>
            </div>
        </>

        const rowExpansionTemplate = (data) => <DisplayShieldDetails data={data} />

        return <div className='col-12'>
            <div className='card'>
                <DisplayComparisonTitle type="Integrated Shield Hospital Insurance" />
                <Suspense fallback={<div>Loading Comparison Table...</div>}>
                    <DataTable value={displayData}
                        stripedRows
                        responsiveLayout="scroll"

                        // header
                        header={renderHeader()}

                        // footer
                        // footer={<DataTableFooter type="integratedShield" query={query} />}

                        // expander props
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        dataKey="_id"

                        // keyword search props
                        filters={filters2} filterDisplay="row"

                    >
                        <Column expander style={{ width: '3em' }} />
                        <Column field="insurer_shortName" header="Insurer" sortable></Column>
                        <Column field="plan_shortName" header="Plan" sortable></Column>
                        <Column field="premium_MediSave" header="MediSave" body={formatMediSaveCurrency} sortable></Column>
                        <Column field="premium_Cash" header="Cash" body={formatCashCurrency} sortable></Column>
                        <Column field="premium_Total" header="Total" body={formatTotalCurrency} sortable></Column>
                        <Column field="promo" header="Promo" body={formatPromo} sortable></Column>
                    </DataTable>
                </Suspense>
            </div>
        </div>

    }, [displayData, expandedRows, filters2, globalFilterValue2, mobileScreen, query])

    return (
        <div className='grid'>
            {
                !readyToSearch && generateComparison()
            }
            <div className='col-12 md:col-6'>
                <div className='card'>
                    <h5>Select Residency</h5>
                    <SelectButton
                        value={formik.values['residency']}
                        options={residency}
                        optionLabel="name"
                        onChange={(e) => formik.setFieldValue("residency", e.value)}
                        optionDisabled="disabled" />

                    <h5>Integrated Shield Plan Option</h5>
                    <SelectButton value={withRider} disabled={!readyToSearch} options={ipWithOrWithoutRider} onChange={(e) => handleIpOption(e.value)} />
                    <h5>Coverage Type</h5>
                    {displayFormikLabel("comparisonType", "", formik.touched, formik.errors)}
                    {
                        (withRider ? ipWithRider : ipWithoutRider).map((cover, index) => {
                            return (
                                <div key={index} className="field-checkbox">
                                    <Checkbox
                                        inputId={index}
                                        name="type"
                                        value={cover.key}
                                        onChange={e => formik.setFieldValue("comparisonType", e.value)}
                                        checked={formik.values.comparisonType === cover.key}
                                        disabled={!readyToSearch} />

                                    <label htmlFor={index}>{cover.name}</label>
                                </div>
                            )
                        })
                    }
                    <div className='mt-3'>
                        <hr></hr>
                        <div className='grid mt-3'>
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="button" label="Reset" aria-label="Reset" className='w-full p-button-outlined' onClick={handleReset} />
                            </div>
                            {
                                showEditButton && <div className='col-12 md:col-4'>
                                    <Button type="button" label="Edit" aria-label="Reset" className='w-full p-button-secondary' onClick={handleEdit} />
                                </div>
                            }
                            <div className={showEditButton ? 'col-12 md:col-4' : 'col-12 md:col-6'}>
                                <Button type="submit" label="Search" aria-label="Search" className='w-full' disabled={!readyToSearch} onClick={formik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 md:col-6'>
                <DisplayInsuredDetails />
                <FormInsurerDisplayedOption comparisonType={userContext_integratedShield} insurerList={insurer_integratedShield} />
            </div>
            {loading && <LoadingScreen />}
        </div>
    )
}

export default CompareShield
